<template>
    <div ref="container" height="100vh">
        <v-card :loading="loading" class="pa-2">
            <v-card-title ref="title">
                <v-btn
                    small
                    icon
                    @click="close"
                    color="primary"
                    class="ml-n3 mr-2"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h2 class="font-weight-regular">
                    Invoice Details /
                    <span class="font-weight-black text-uppercase">
                        PROJECT: {{ project.name }}</span
                    >
                </h2>
                <v-btn
                    v-if="invoice.rejectionReasons"
                    small
                    icon
                    @click="rejectionTable = true"
                    color="red"
                    class="ml-2"
                >
                    <v-icon>
                        mdi-book-cancel-outline
                    </v-icon>
                </v-btn>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-text
                id="virtual-scroll-table"
                class="px-4"
                :style="`height: ${height}px`"
            >
                <v-row ref="details_1">
                    <v-col cols="12" xl="4" lg="4" md="4">
                        <v-text-field
                            v-if="concepts.length > 0"
                            outline
                            v-model="selectedConcept"
                            prepend-icon="mdi-table-column"
                            prefix="Concept: "
                            hide-details
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4">
                        <v-text-field
                            outline
                            v-model="invoice.region"
                            prepend-icon="mdi-earth"
                            prefix="Region: "
                            hide-details
                            readonly
                        />
                    </v-col>
                    <v-col cols="12" xl="4" lg="4" md="4">
                        <v-text-field
                            hide-details
                            v-model="invoice.notes"
                            prepend-icon="mdi-text"
                            prefix="Notes: "
                            outline
                            readonly
                        />
                    </v-col>
                </v-row>
                <v-row ref="details_2" class="ma-0 pa-0">
                    <v-col cols="12" xl="4" lg="4" md="4">
                        <div class="d-flex my-2">
                            <div class="d-flex align-center">
                                <strong>ATTACHMENTS: </strong>
                            </div>
                            <div class="d-flex align-center flex-wrap">
                                <v-btn
                                    color="grey lighten-3"
                                    elevation="0"
                                    x-small
                                    class="ma-1 text-capitalize grey--text text--darken-1  "
                                    v-for="(file, i) in invoice.attachments"
                                    :key="i"
                                >
                                    <span @click="openFileAttached(file)">{{
                                        file
                                    }}</span>
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                    <v-col
                        v-if="
                            invoice.attached &&
                                (invoice.status == 'billed' ||
                                    invoice.status == 'approved' ||
                                    invoice.status == 'closed' ||
                                    invoice.status == 'credit')
                        "
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                    >
                        <v-text-field
                            outline
                            style="color:blue;"
                            v-model="invoice.invoiceNumber"
                            prepend-icon="mdi-receipt-text-outline"
                            prefix="Invoice No: "
                            :disabled="
                                invoice.status == 'billed' ||
                                    invoice.status == 'approved' ||
                                    invoice.status == 'closed' ||
                                    invoice.status == 'credit'
                            "
                            :rules="[rules.required]"
                        />
                    </v-col>
                    <v-col
                        v-if="
                            invoice.attached &&
                                (invoice.status == 'billed' ||
                                    invoice.status == 'approved')
                        "
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                    >
                        <v-chip
                            class="mt-4 ml-1"
                            @click="loadFile(invoice.attached)"
                        >
                            <v-icon class="mr-1">mdi-paperclip</v-icon>
                            <span>{{ invoice.attached }}</span>
                        </v-chip>
                    </v-col>
                    <v-col
                        v-if="invoice.status == 'closed'"
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                        class="d-flex flex-wrap"
                    >
                        <div class="d-flex mr-2">
                            <h3 class="mt-5">Invoice:</h3>
                            <v-chip
                                v-if="invoice.attached"
                                class="mt-4 ml-1 pr-10"
                                style="max-width: 200px"
                                @click="loadFile(invoice.attached)"
                            >
                                <v-icon>mdi-paperclip</v-icon>
                                <span class="col-12 text-truncate">{{
                                    invoice.attached
                                }}</span>
                            </v-chip>
                        </div>
                        <div class="d-flex" v-if="creditFiles.length > 0">
                            <h3 class="mt-5">Credit notes:</h3>
                            <v-chip
                                class="mt-4 ml-1"
                                v-for="(file, i) in creditFiles"
                                :key="i"
                                @click="loadFile(file)"
                                style="max-width: 200px"
                            >
                                <v-icon class="mr-1">mdi-paperclip</v-icon>
                                <span>{{ file }}</span>
                            </v-chip>
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="validateConcept" no-gutters>
                    <v-col cols="12" ls="2" md="2" sm="2">
                        <div class="d-flex justity-center">
                            <v-text-field
                                type="number"
                                hide-details
                                v-model="invoice.amount"
                                prepend-icon="mdi-text"
                                prefix="Amount: "
                                outline
                                readonly
                            />
                        </div>
                    </v-col>
                </v-row>
                <v-row v-if="!validateConcept" no-gutters class="mt-8">
                    <v-col
                        cols="12"
                        v-for="quoteId of invoice.items
                            ? Object.keys(invoice.items)
                            : []"
                        :key="quoteId"
                        class="mb-6"
                    >
                        <v-data-table
                            v-if="validateInvoiceItems(invoice.items[quoteId])"
                            :headers="headers"
                            :items="filterItems(invoice.items[quoteId])"
                            class="elevation-0"
                            :mobile-breakpoint="0"
                            :loading="loading"
                            disable-pagination
                            hide-default-footer
                        >
                            <template v-slot:top>
                                <v-row
                                    no-gutter
                                    style="background-color: #eeeeee"
                                    class="mt-0 mx-0"
                                >
                                    <v-col cols="4" class="d-flex">
                                        <h2 class="mt-1">
                                            QUOTE:
                                            {{ getQuoteName(quoteId) }}
                                        </h2>
                                    </v-col>
                                </v-row>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.index`]="{ index }">
                                {{ index + 1 }}
                            </template>
                            <template v-slot:[`item.name`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{ item.name }}
                                </p>
                            </template>
                            <template v-slot:[`item.pricePerUnit`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{ numberFormat(item.pricePerUnit) }}
                                </p>
                            </template>
                            <template v-slot:[`item.quantity`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{
                                        item.invoiceQuantity ||
                                            item.billedQty ||
                                            item.creditNoteQty ||
                                            0
                                    }}
                                    {{
                                        item.creditQty
                                            ? `(-${item.creditQty})`
                                            : ''
                                    }}
                                </p>
                            </template>
                            <template v-slot:[`item.currentInvoice`]="{ item }">
                                <p class="my-0 font-weight-bold">
                                    {{
                                        numberFormat(
                                            (item.invoiceQuantity ||
                                                item.billedQty ||
                                                item.creditNoteQty ||
                                                0) * item.pricePerUnit
                                        )
                                    }}
                                </p>
                            </template>
                            <!--FOOTER-->
                            <template v-slot:[`body.append`]>
                                <tr style="background-color: #eeeeee">
                                    <td class="font-weight-bold"></td>
                                    <td />
                                    <td class="font-weight-bold"></td>
                                    <td class="font-weight-bold"></td>
                                    <td class="font-weight-bold text-center">
                                        {{
                                            quoteTotalQty(
                                                filterItems(
                                                    invoice.items[quoteId]
                                                )
                                            )
                                        }}
                                    </td>
                                    <td class="font-weight-bold text-center">
                                        {{
                                            numberFormat(
                                                quoteTotalInvoice(
                                                    filterItems(
                                                        invoice.items[quoteId]
                                                    )
                                                )
                                            )
                                        }}
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
                <v-row
                    v-if="!validateConcept"
                    no-gutters
                    style="background-color: #eeeeee"
                    class="mt-8"
                    ref="totals"
                >
                    <v-col cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black ml-4">TOTALS:</p>
                    </v-col>
                    <v-col v-if="!inProcess" cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black">
                            PROJECT PRICE: {{ numberFormat(project.price) }}
                        </p>
                    </v-col>
                    <v-col v-if="!inProcess" cols="12" xl="2" lg="2" md="2">
                        <p class="my-2 font-weight-black">
                            PROJECT BILLED:
                            {{ numberFormat(projectBilled()) }}
                        </p>
                    </v-col>
                    <v-spacer />
                    <v-col
                        cols="12"
                        xl="4"
                        lg="4"
                        md="4"
                        class="d-flex justify-space-between"
                    >
                        <p class="my-2 font-weight-black">
                            Qty: {{ invoiceTotalQty().toFixed(2) }}
                        </p>
                        <p class="my-2 font-weight-black mr-4">
                            Current Invoice:
                            {{ numberFormat(invoiceTotalPrice()) }}
                            {{
                                invoiceTotalCredit() > 0
                                    ? `(-${numberFormat(invoiceTotalCredit())})`
                                    : ''
                            }}
                        </p>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions ref="actions">
                <v-btn
                    v-if="
                        (invoice.status == 'international' &&
                            approvalInternationalPermission) ||
                            (invoice.status == 'generating' &&
                                generateInvoicePermission)
                    "
                    rounded
                    color="red"
                    class="mb-4 mr-2 white--text"
                    @click="openRejectForm"
                >
                    REJECT
                </v-btn>
                <v-btn
                    v-if="
                        invoice.status == 'billed' && userId == projectManager
                    "
                    rounded
                    color="red"
                    class="mb-4 mr-2 white--text"
                    @click="creditNoteProcess = true"
                >
                    REQUEST CREDIT NOTE
                </v-btn>
                <v-spacer />
                <v-btn
                    v-if="
                        invoice.region == 'international' &&
                            inProcess &&
                            (!invoice.status || invoice.status == 'rejected')
                    "
                    rounded
                    color="#EDC43B"
                    class="mb-4 mr-2 white--text"
                    @click="sendInvoice"
                >
                    SEND
                </v-btn>
                <v-btn
                    v-if="
                        invoice.region == 'international' &&
                            invoice.status == 'international' &&
                            inProcess
                    "
                    rounded
                    color="#27BC4F"
                    class="mb-4 mr-2 white--text"
                    @click="sentToGenerate"
                >
                    SENT TO GENERATE
                </v-btn>
                <v-btn
                    v-if="invoice.region == 'local' && inProcess"
                    rounded
                    color="#EDC43B"
                    class="mb-4 mr-2 white--text"
                    @click="confirm"
                >
                    CONFIRM SEND
                </v-btn>
                <v-btn
                    v-if="invoice.status == 'billed'"
                    rounded
                    color="primary"
                    class="mb-4 mr-2 white--text"
                    @click="openApproveDialog = true"
                >
                    APPROVE
                </v-btn>
            </v-card-actions>
        </v-card>
        <!--Reject Invoice-->
        <v-dialog
            v-model="rejectForm"
            persistent
            max-width="600px"
            :retain-focus="false"
        >
            <v-card :loading="loading" v-if="rejectForm">
                <v-card-title class="text-h5">Reject</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            Write the reasons why you decide to reject this
                            invoice
                        </v-col>
                    </v-row>
                    <v-form v-model="valid">
                        <v-row no-gutters class="mb-2 mt-n5">
                            <v-col cols="12">
                                <v-textarea
                                    v-model="rejectedNotes"
                                    rows="2"
                                    hide-details
                                    label="Notes"
                                    prepend-icon="mdi-text"
                                    required
                                    :rules="[rules.required]"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-btn text color="secondary" @click="closeRejectForm">
                        Close
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="rejectInvoice"
                        text
                        color="error"
                        :loading="loading"
                        :disabled="!valid"
                    >
                        Reject
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <!--Error Alert-->
        <Errors />
        <!--Rejected-->
        <v-dialog persistent max-width="600px" v-model="rejectionTable">
            <RejectedInvoicesTable
                v-if="rejectionTable"
                :items="invoice.rejectionReasons"
                @closeDialog="rejectionTable = false"
            />
        </v-dialog>
        <!--Credit request-->
        <v-dialog
            v-model="creditNoteProcess"
            :retain-focus="false"
            persistent
            fullscreen
        >
            <CreditNoteRequest
                v-if="creditNoteProcess"
                :invoice="invoice"
                :project="project"
                :setting="setting"
                @close="closeCreditNoteProcess"
                @replaceInvoice="replaceInvoice"
            />
        </v-dialog>
        <!-- APPROVE INVOICE -->
        <v-dialog
            v-model="openApproveDialog"
            :retain-focus="false"
            persistent
            max-width="600"
        >
            <v-card :loading="loading">
                <v-card-title class="text-h5">
                    <v-btn
                        small
                        icon
                        @click="closeApproveDialog"
                        color="primary"
                        class="mr-2"
                    >
                        <v-icon>
                            mdi-close
                        </v-icon>
                    </v-btn>
                    <h3>
                        Approve Invoice
                        {{ invoice ? invoice.number : '' }}
                    </h3></v-card-title
                >
                <v-divider class="ml-4 mr-5" />
                <v-card-text class="pt-4">
                    Are you sure you want to approve it?
                </v-card-text>
                <v-card-actions class="pb-4">
                    <v-spacer></v-spacer>
                    <v-btn
                        @click="approveInvoice"
                        color="primary"
                        :loading="loading"
                        rounded
                    >
                        APPROVE
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'
import { openFile } from '@/services/storage/openFile.js'
import { saveFile } from '@/services/storage/saveFile.js'
import { loadImage } from '@/helpers/imageHandler.js'
import { auth } from '@/services/firebase'

export default {
    name: 'InvoiceDetails',
    props: {
        project: {
            type: Object,
            required: true,
        },
        invoice: {
            type: Object,
            required: true,
        },
        inProcess: {
            type: Boolean,
            default: () => false,
        },
        setting: {
            type: Object,
            required: true,
        },
        localAttachments: {
            type: Array,
            default: () => [],
        },
    },
    components: {
        Errors: () => import('@/components/Layout/Errors'),
        RejectedInvoicesTable: () =>
            import('@/components/Invoicing/RejectedInvoicesTable'),
        CreditNoteRequest: () =>
            import('@/components/Invoicing/CreditNoteRequest.vue'),
    },
    data: () => ({
        generateInvoicePermission: false,
        approvalInternationalPermission: false,
        openApproveDialog: false,
        rejectionTable: false,
        rejectedNotes: null,
        valid: false,
        loading: true,
        rules: {
            required: v => !!v || 'The value is required',
        },
        concepts: [],
        types: [],
        categories: [],
        regions: ['international', 'local'],
        height: 0,
        headers: [
            {
                text: 'INDEX',
                value: 'index',
                sortable: false,
                align: 'center',
            },
            {
                text: 'ITEM',
                value: 'name',
                sortable: false,
            },
            {
                text: 'CATEGORY',
                value: 'category',
                sortable: false,
            },
            {
                text: 'UNIT PRICE',
                value: 'pricePerUnit',
                sortable: false,
            },
            {
                text: 'QTY',
                value: 'quantity',
                sortable: false,
                align: 'center',
            },
            {
                text: 'CURRENT INVOICE',
                value: 'currentInvoice',
                sortable: false,
                align: 'center',
            },
        ],
        rejectForm: false,
        companyId: JSON.parse(localStorage.getItem('company')),
        selectedFile: null,
        invoices: [],
        dataProject: {},
        userId: '',
        projectManager: '',
        creditNote: false,
        creditNoteProcess: false,
        creditFiles: [],
    }),
    inject: {
        addInvoice: {
            from: 'addInvoice',
            default() {
                return null
            },
        },
        updateInvoice: {
            from: 'updateInvoice',
            default() {
                return null
            },
        },
        confirmSend: {
            from: 'confirmSend',
            default() {
                return null
            },
        },
    },
    computed: {
        selectedConcept() {
            const concept = this.concepts.find(
                c => c.id == this.invoice.concept
            )
            return concept ? concept.name : ''
        },
        validateConcept() {
            if (this.invoice.concept) {
                const concept = this.getConcept(this.invoice.concept)
                if (concept) {
                    return concept.enabledValue
                }
            }
            return false
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.userId = auth().currentUser.uid
            this.projectManager = this.invoice.project.projectManager
            this.categories = this.setting.categories
            this.concepts = this.setting.concepts
            this.types = this.setting.types
            this.dataProject = await API.getProject(this.project.id)
            if (
                this.invoice.project.quotes &&
                this.invoice.project.quotes.length > 0 &&
                typeof this.invoice.project.quotes[0] == 'string'
            ) {
                this.invoice.project.quotes = await API.getProjectQuotes(
                    this.invoice.project.id
                )
            }
            if (this.dataProject.invoices) {
                const index = this.dataProject.invoices.findIndex(
                    i => i.id == this.invoice.id
                )

                if (
                    index > -1 &&
                    this.dataProject.invoices[index].creditNotes
                ) {
                    this.creditFiles = this.dataProject.invoices[
                        index
                    ].creditNotes
                        .filter(i => i.credit)
                        .map(i => i.credit)
                }
            }

            if (this.invoice.credit) {
                this.creditFiles.push(this.invoice.credit)
            }
            const {
                data: { user },
            } = await API.getMyInfo()
            this.approvalInternationalPermission = user.permissions.includes(
                'approvalInternational'
            )
            this.generateInvoicePermission = user.permissions.includes(
                'generateInvoice'
            )
            this.onResize()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        getConcept(id) {
            return this.concepts.find(c => c.id == id)
        },
        closeApproveDialog() {
            this.openApproveDialog = false
        },
        async approveInvoice() {
            try {
                this.loading = false
                await this.$emit('approveInvoice')
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeCreditNote() {
            this.creditNote = false
        },
        async loadFile(file) {
            try {
                this.loading = true
                var url = await loadImage(
                    `${this.companyId}/projects/${this.invoice.projectId}/invoices`,
                    file
                )
                if (url) {
                    setTimeout(() => {
                        window.open(url, '_blank')
                        this.loading = false
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        async openFileAttached(file) {
            try {
                this.loading = true
                if (!this.invoice.status) {
                    const localFile = this.localAttachments.find(
                        f => f.name == file
                    )
                    if (localFile) {
                        const url = URL.createObjectURL(localFile)
                        window.open(url, '_blank')
                    }
                    return null
                }
                await openFile(
                    file,
                    `${this.companyId}/projects/${this.project.id}/invoices`
                )
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        filterItems(items) {
            return items.filter(item => {
                if (
                    this.inProcess ||
                    this.invoice.status == 'international' ||
                    this.invoice.status == 'generating' ||
                    this.invoice.status == 'rejected'
                ) {
                    if (item.invoiceQuantity) {
                        return true
                    }
                } else if (this.invoice.status == 'credit') {
                    if (item.invoiceQuantity || item.creditNoteQty) {
                        return true
                    }
                } else if (this.invoice.status == 'closed') {
                    if (
                        item.billedQty ||
                        item.invoiceQuantity ||
                        item.creditNoteQty ||
                        item.creditNoteQty ||
                        0
                    ) {
                        return true
                    }
                } else {
                    if (item.billedQty) {
                        return true
                    }
                    item.creditNoteQty || 0
                }
            })
        },
        validateInvoiceItems(costs) {
            if (costs.length == 0) {
                return false
            }
            let response = false
            for (const cost of costs) {
                if (
                    this.inProcess ||
                    this.invoice.status == 'international' ||
                    this.invoice.status == 'generating' ||
                    this.invoice.status == 'rejected'
                ) {
                    if (cost.invoiceQuantity) {
                        response = true
                        break
                    }
                } else if (this.invoice.status == 'credit') {
                    if (cost.invoiceQuantity || cost.creditNoteQty) {
                        response = true
                        break
                    }
                } else if (this.invoice.status == 'closed') {
                    if (
                        cost.billedQty ||
                        cost.invoiceQuantity ||
                        cost.creditNoteQty
                    ) {
                        response = true
                        break
                    }
                } else {
                    if (cost.billedQty) {
                        response = true
                        break
                    }
                }
            }
            return response
        },
        projectBilled() {
            return this.project.totalInvoiced || 0
        },
        projectPrice() {
            let total = 0
            for (const quoteId of Object.keys(this.invoice.items)) {
                for (const cost of this.invoice.items[quoteId]) {
                    total += cost.qty * cost.pricePerUnit
                }
            }
            return total
        },
        numberFormat(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: this.invoice.currency,
                maximumFractionDigits: 2,
            }).format(number)
        },
        invoiceTotalPrice() {
            let total = 0
            for (const quoteId of Object.keys(this.invoice.items)) {
                for (const cost of this.invoice.items[quoteId]) {
                    if (
                        this.invoice.status != 'billed' &&
                        this.invoice.status != 'closed' &&
                        this.invoice.status != 'approved'
                    ) {
                        if (cost.invoiceQuantity) {
                            total += cost.invoiceQuantity * cost.pricePerUnit
                        }
                    } else {
                        if (cost.currentInvoice) {
                            total += cost.currentInvoice
                        }
                    }
                }
            }

            return total
        },
        invoiceTotalCredit() {
            let totalCredit = 0
            if (this.invoice.totalCredit) {
                totalCredit = this.invoice.totalCredit
            } else {
                for (const quoteId of Object.keys(this.invoice.items)) {
                    for (const cost of this.invoice.items[quoteId]) {
                        if (cost.creditNoteQty) {
                            totalCredit +=
                                cost.creditNoteQty * cost.pricePerUnit
                        }
                    }
                }
            }
            return totalCredit
        },
        invoiceTotalQty() {
            let total = 0
            for (const quoteId of Object.keys(this.invoice.items)) {
                for (const cost of this.invoice.items[quoteId]) {
                    if (
                        this.invoice.status != 'billed' &&
                        this.invoice.status != 'closed' &&
                        this.invoice.status != 'approved'
                    ) {
                        if (cost.invoiceQuantity) {
                            total += cost.invoiceQuantity
                        }
                    } else {
                        if (cost.currentInvoice) {
                            total += cost.billedQty
                        }
                    }
                }
            }
            return total
        },
        quoteTotalInvoice(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator +
                    cost.pricePerUnit *
                        (cost.invoiceQuantity || cost.billedQty),
                0
            )
        },
        quoteTotalQty(costs) {
            return costs.reduce(
                (accumulator, cost) =>
                    accumulator + (cost.invoiceQuantity || cost.billedQty),
                0
            )
        },
        getQuoteName(quoteId) {
            let quote = this.invoice.project.quotes.find(
                quote => quote.id == quoteId
            )
            return quote.name
        },
        async sentToGenerate() {
            try {
                this.loading = true
                await API.sentToGenerate({
                    id: this.invoice.id,
                    projectId: this.invoice.projectId,
                    notificationId: 'WNzjPtOPmiwPEuv0vRc5',
                })
                this.$emit('sentToGenerate', this.invoice.id)
                this.close()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async rejectInvoice() {
            try {
                this.loading = true
                const invoice = await API.rejectInvoice({
                    id: this.invoice.id,
                    projectId: this.invoice.projectId,
                    notes: this.rejectedNotes,
                    notificationId: 'bgOJPzzceetopCQj5kxs',
                })
                this.rejectForm = false
                this.$emit('replaceInvoice', invoice)
                this.$emit('close')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeRejectForm() {
            this.rejectForm = false
        },
        openRejectForm() {
            this.rejectForm = true
        },
        onResize() {
            const {
                title: { clientHeight: titleHeight },
            } = this.$refs

            this.height = window.innerHeight - titleHeight - 85
        },
        close() {
            this.$emit('close')
        },
        async sendInvoice() {
            try {
                this.loading = true
                for (const quoteId of Object.keys(this.invoice.items)) {
                    let items = []
                    for (const cost of this.invoice.items[quoteId]) {
                        delete cost.billedQty
                        delete cost.logs
                        if (cost.invoiceQuantity > 0) {
                            items.push(cost)
                        }
                    }
                    this.invoice.items[quoteId] = items
                }
                if (
                    !this.invoice.status &&
                    this.invoice.attachments &&
                    this.invoice.attachments.length > 0
                ) {
                    for (const file of this.localAttachments) {
                        await saveFile(
                            file,
                            `${this.companyId}/projects/${this.project.id}/invoices`
                        )
                    }
                }
                if (!this.invoice.status) {
                    delete this.invoice.project
                    const invoice = await API.createInvoice({
                        settingId: this.setting.id,
                        notificationId: 'AkAPWh7qBWxCfCL12WqN',
                        ...this.invoice,
                    })
                    this.addInvoice(invoice)
                } else if (this.invoice.status == 'rejected') {
                    delete this.invoice.credit
                    const invoice = await API.updateInvoice(this.invoice)
                    this.updateInvoice(invoice)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async confirm() {
            try {
                this.loading = true
                for (const quoteId of Object.keys(this.invoice.items)) {
                    let items = []
                    for (const cost of this.invoice.items[quoteId]) {
                        delete cost.billedQty
                        delete cost.logs
                        if (cost.invoiceQuantity > 0) {
                            items.push(cost)
                        }
                    }
                    this.invoice.items[quoteId] = items
                }
                if (
                    !this.invoice.status &&
                    this.invoice.attachments &&
                    this.invoice.attachments.length > 0
                ) {
                    for (const file of this.localAttachments) {
                        await saveFile(
                            file,
                            `${this.companyId}/projects/${this.project.id}/invoices`
                        )
                    }
                }
                if (!this.invoice.status) {
                    delete this.invoice.project
                    const invoice = await API.createInvoice({
                        settingId: this.setting.id,
                        notificationId: 'WNzjPtOPmiwPEuv0vRc5',
                        ...this.invoice,
                    })
                    this.confirmSend(invoice)
                } else if (this.invoice.status == 'rejected') {
                    const { credit, ...data } = this.invoice
                    credit
                    const invoice = await API.updateInvoice(data)
                    this.updateInvoice(invoice)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        closeCreditNoteProcess() {
            this.creditNoteProcess = false
            this.proformaDialog = false
        },
        replaceInvoice(invoice) {
            this.$emit('replaceInvoice', invoice)
            this.close()
        },
    },
}
</script>

<style>
#virtual-scroll-table {
    width: 100%;
    overflow: scroll;
}
</style>
